.background { background: var(--background-color); }
.backgroundOver { background: var(--background-hover-color); }
.backgroundOver:hover { background: var(--background-hover-color); }
.backgroundPrimary { background: var(--primary-color); }
.backgroundPrimaryAlpha { background: var(--primary-color-alpha); }
.backgroundPrimaryHover:hover { background: var(--primary-color); color: var(--white-color); fill: var(--white-color);}
.backgroundDanger { background: var(--danger-color); }
.backgroundBlack { background: var(--black-color); }
.backgroundOverlay { background: rgba(0, 0, 0, 0.4); }
.foreground { background: var(--foreground-color); }
.backgroundDivider { background: var(--divider-color); }

.backgroundPrimaryLock { background: var(--primary-color) !important; }

.color { color: var(--text-color); fill: var(--text-color); }
.colorPrimary { color: var(--primary-color); fill: var(--primary-color); }
.colorHoverPrimary:hover { color: var(--primary-hover-color); fill: var(--primary-hover-color); }
.colorWhite { color: var(--white-color); fill: var(--white-color); }
.colorBlack { color: var(--black-color); fill: var(--black-color); }
.colorGray { color: var(--gray-color); fill: var(--gray-color); }
.colorDanger { color: var(--danger-color); fill: var(--danger-color); }
.colorHoverDanger:hover { color: var(--danger-hover-color); fill: var(--danger-hover-color); }
.colorYellow { color: var(--yellow-color); fill: var(--yellow-color); }
.colorHoverYellow:hover { color: var(--yellow-hover-color); fill: var(--yellow-hover-color); }
.colorSuccess { color: var(--success-color); fill: var(--success-color); }
.colorHoverBlack:hover { color: var(--black-color); fill: var(--black-color); }
.colorHoverWhite:hover { color: var(--white-color); fill: var(--white-color); }

.textNoBreak { white-space: nowrap; }
.textBreak { white-space: break-spaces; word-break: break-word; }
.textOverflow { text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
.textCenter { text-align: center; }

.button { background: var(--button-background-color); color: var(--button-text-color); border-radius: var(--border-radius); border: none; padding: 6px 12px; cursor: pointer; outline: none; user-select: none; }
.buttonPrimary { background: var(--primary-color); color: var(--white-color); border-radius: var(--border-radius); border: none; padding: 6px 12px; font-family: "font bold", sans-serif; cursor: pointer; outline: none; user-select: none; }
.buttonDanger { background: var(--danger-color); color: var(--white-color); border-radius: var(--border-radius); border: none; padding: 6px 12px; font-family: "font bold", sans-serif; cursor: pointer; outline: none; user-select: none; }

.button:hover { background: var(--button-background-hover-color); }
.buttonPrimary:hover { background: var(--primary-hover-color); }
.buttonDanger:hover { background: var(--danger-hover-color); }

.input { padding: 6px 12px; border-radius: var(--border-radius); background: var(--input-background-color); color: var(--input-text-color); border: none; outline: none; }
.noInput { background: none; border: none; outline: none; color: var(--input-text-color); }
.noResize { resize: none; }

.border { border: 1px solid var(--border-color); }
.borderNone { border: 1px solid var(--background-color); }
.borderPrimary { border: 1px solid var(--primary-color); }
.borderBottom { border: none; border-bottom: 1px solid var(--divider-color); margin: 0; }
.borderY { border: none; border-left: 1px solid var(--border-color); margin: 0; }
.borderDividerY { border: none; border-top: 1px solid var(--divider-color);  border-bottom: 1px solid var(--divider-color); margin: 0; }
.borderRadius { border-radius: var(--border-radius); }
.borderCircle { border-radius: 100%; }
.borderTopLeftRadius { border-top-left-radius: var(--border-radius); }
.borderTopRightRadius { border-top-right-radius: var(--border-radius); }
.borderDanger { border: 1px solid var(--danger-color); }
.noBorderTopLeft { border-top-left-radius: 0; }
.noBorderTopRight { border-top-right-radius: 0; }
.noBorderBottomLeft { border-bottom-left-radius: 0; }
.noBorderBottomRight { border-bottom-right-radius: 0; }

.cursorPointer { cursor: pointer; }
.cursorDefault { cursor: default !important; }
.noSelect { user-select: none; }

.imageContain img { width: 100%; height: 100%; object-fit: contain; }
.imageCover img { width: 100%; height: 100%; object-fit: cover; }

.dotted-line {
    border-bottom: 2px var(--divider-color) dotted;
}
.divider {
    border-bottom: 1px var(--divider-color) double ;
}



/*dot*/
.dot { width: 7px; height: 7px; background: var(--primary-color); border-radius: 100%; transition: left 500ms; }

.hidden{
    visibility: hidden;
}
